export default {
    state: () => ({
      isLoggedIn: false
    }),
    mutations: {
      setLoggedIn(state, status) {
        state.isLoggedIn = status;
      },
    },
    actions: {
      login({ commit }, status) {
        localStorage.setItem("loggedIn", status);
        commit("setLoggedIn", status);
      },
      logout({ commit }) {
        localStorage.removeItem("loggedIn");
        commit("setLoggedIn", false);
      },
    },
    getters: {
      isLoggedIn: (state) => state.isLoggedIn,
    },
  };