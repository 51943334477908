import axios from "@/gateway/backendapi";

export const productType = ["Ebook", "AudioBook", "Video", "Music", "HardCopy", "PhysicalItem", "Others"]

export const getProductCategories = async () => {
    try {
        let { data } = await axios.get("/api/Ecommerce/getAllCategories");
        return data
    } catch (error) {
        return error
    }
}

export const addProductCategory = async (payload) => {
    try {
        let { data } = await axios.post("/api/Ecommerce/AddCategory", payload);
        return data
    } catch (error) {
        return error
    }
}

export const addProduct = async (payload) => {
    try {
        let { data } = await axios.post("/api/Ecommerce/AddProduct", payload);
        return data
    } catch (error) {
        return error
    }
}

export const getAllProduct = async () => {
    try {
        let { data } = await axios.get("/api/Ecommerce/GetAllProducts");
        return data
    } catch (error) {
        return error
    }
}
export const deleteSingleProduct = async (id) => {
    try {
        let { data } = await axios.delete(`/api/Ecommerce/DeleteProduct?productId=${id}`);
        return data
    } catch (error) {
        return error
    }
}

export const updateProductCategory = async (payload) => {
    try {
        let { data } = await axios.put(`/api/Ecommerce/UpdateCategory`, payload);
        return data
    } catch (error) {
        return error
    }
}

export const deleteProductCategory = async (id) => {
    try {
        let { data } = await axios.delete(`/api/Ecommerce/DeleteCategory?categoryId=${id}`);
        return data
    } catch (error) {
        return error
    }
}

export const getProductById = async (id) => {
    try {
        let { data } = await axios.get(`/api/Ecommerce/GetSingleProductById?productId=${id}`);
        return data
    } catch (error) {
        return error
    }
}

export const updateProduct = async (payload) => {
    try {
        let { data } = await axios.put(`/api/Ecommerce/UpdateProduct`, payload);
        return data
    } catch (error) {
        return error
    }
}

export const createEcommerceSetup = async (payload) => {
    return new Promise((resolve, reject) => {
        axios.post("/api/Ecommerce/AddSetup", payload)
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                /*eslint no-undef: "warn"*/
                NProgress.done();
                if (error.response) {
                    reject(error.response);
                } else {
                    reject(error);
                }
            })
    })
}
export const recentOrder = async () => {
    return new Promise((resolve, reject) => {
        axios.get("/api/Ecommerce/Dashboard/GetRecentOrders")
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                /*eslint no-undef: "warn"*/
                NProgress.done();
                if (error.response) {
                    reject(error.response);
                } else {
                    reject(error);
                }
            })
    })
}
export const orderStatusTrend = async () => {
    return new Promise((resolve, reject) => {
        axios.get("/api/Ecommerce/Dashboard/GetOrderStatusTrend")
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                /*eslint no-undef: "warn"*/
                NProgress.done();
                if (error.response) {
                    reject(error.response);
                } else {
                    reject(error);
                }
            })
    })
}
export const allOrder = async (page) => {
    return new Promise((resolve, reject) => {
        axios.get(`/api/Ecommerce/GetAllOrders?page=${page}`)
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                // /*eslint no-undef: "warn"*/
                // NProgress.done();
                if (error.response) {
                    reject(error.response);
                } else {
                    reject(error);
                }
            })
    })
}
export const salesTrend = async () => {
    return new Promise((resolve, reject) => {
        axios.get("/api/Ecommerce/Dashboard/GetSalesTrend")
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                /*eslint no-undef: "warn"*/
                NProgress.done();
                if (error.response) {
                    reject(error.response);
                } else {
                    reject(error);
                }
            })
    })
}
export const orderTrend = async () => {
    return new Promise((resolve, reject) => {
        axios.get("/api/Ecommerce/Dashboard/GetOrderTrend")
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                /*eslint no-undef: "warn"*/
                NProgress.done();
                if (error.response) {
                    reject(error.response);
                } else {
                    reject(error);
                }
            })
    })
}
export const revenueTrend = async () => {
    return new Promise((resolve, reject) => {
        axios.get("/api/Ecommerce/Dashboard/GetRevenueTrend")
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                /*eslint no-undef: "warn"*/
                NProgress.done();
                if (error.response) {
                    reject(error.response);
                } else {
                    reject(error);
                }
            })
    })
}
export const keyMetrics = async () => {
    return new Promise((resolve, reject) => {
        axios.get("/api/Ecommerce/Dashboard/KeyMetrics")
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                /*eslint no-undef: "warn"*/
                NProgress.done();
                if (error.response) {
                    reject(error.response);
                } else {
                    reject(error);
                }
            })
    })
}
export const topSellingProducts = async () => {
    return new Promise((resolve, reject) => {
        axios.get("/api/Ecommerce/Dashboard/GetTopSellingProducts")
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                /*eslint no-undef: "warn"*/
                NProgress.done();
                if (error.response) {
                    reject(error.response);
                } else {
                    reject(error);
                }
            })
    })
}

export const getEcommerceSetup = async () => {
    return new Promise((resolve, reject) => {
        axios.delete("/api/Ecommerce/GetSetup")
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                /*eslint no-undef: "warn"*/
                NProgress.done();
                if (error.response) {
                    reject(error.response);
                } else {
                    reject(error);
                }
            })
    })
}

    export const UpdateEcommerceSetup = async (payload) => {
        return new Promise((resolve, reject) => {
            axios.put("/api/Ecommerce/UpdateSetup", payload)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    /*eslint no-undef: "warn"*/
                    NProgress.done();
                    if (error.response) {
                        reject(error.response);
                    } else {
                        reject(error);
                    }
                })
        })
    }
