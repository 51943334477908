// import { ecommerceservice }  from "../../services/ecommerce/ecommerceservice"
// import axios from "@/gateway/backendapi"
import { getAllProduct, getProductCategories } from "../../services/ecommerce/ecommerceservice"
const defaultState = () => ({
  allProducts: [],
  allCategories: [],
  cart: [],
  id: null,
  cartCount: 0,
  isLoggin: false,
  // products: []
});

export default {
  namespaced: true,
  state: defaultState(),

  mutations: {
    SET_PRODUCTS(state, payload) {
        state.allProducts = payload
    },
    SET_CATEGORIES(state, payload) {
        state.allCategories = payload
    },
    clearState(state) {
        Object.assign(state, defaultState())
      },
    ADD_TO_CART(state) {
      state.cartCount = state.cartCount + 1;
    },
    SET_CART_COUNT(state, payload) {
      state.cartCount = payload;
    },
    REMOVE_FROM_CART(state, productId) {
      state.cart = state.cart.filter((product) => product.id !== productId);
    },
    SET_ID(state, id) {
      // Mutation to set the id
      state.id = id;
    },
    SET_LOGIN(state, payload) {
      // Mutation to set the id
      state.isLoggin = payload;
    },

    // SET_PRODUCTS(state, products) { // Mutation to set products
    //   state.products = products;
    // },
  },

  actions: {
    async getAllProducts({ commit }) {
        return getAllProduct().then(response => {
            commit('SET_PRODUCTS', response)
            return response
        })
    },
    async getProductCategory({ commit }) {
        return getProductCategories().then(response => {
            commit('SET_CATEGORIES', response)
            return response
        })
    },
    setId({ commit }, id) {
      commit("SET_ID", id); // Commit the id to the state
    },
    setLogin({ commit }, payload) {
      commit("SET_LOGIN", payload); // Commit the id to the state
      console.log("ghshshsh");
    },
    setIncrementCart({ commit }) {
      commit("ADD_TO_CART"); // Commit the id to the state
    },
    setCartCount({ commit }, payload) {
      commit("SET_CART_COUNT", payload); // Commit the id to the state
    },
    removeItemFromCart({ commit }, productId) {
      commit("REMOVE_FROM_CART", productId); // Remove the item
    },
    //   getProducts({ commit }) {
    //     return ecommerceservice.getAllProductbyId().then(response => {
    //         commit('SET_PRODUCTS', response)
    //         return response
    //     })
    // },
    clearState({ commit }) {
      commit("clearState");
    },
  },

  getters: {
    getproducts: (state) => {
        return state.allProducts
    },
    getCategories: (state) => {
        return state.allCategories
    },
    cartCount: (state) => {
      return state.cartCount;
    },
    cartItems: (state) => {
      return state.cart;
    },
    getId: (state) => {
      return state.id; // Getter to retrieve the id from the store
    },
    isLoggedIn: (state) => {
      return state.isLoggin; // Getter to retrieve the id from the store
    },
    // allProducts: (state) => {
    //   return state.products;
    // },
  },
};
